import React, { useEffect, useRef } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { BannerLayer, ParallaxBanner } from "react-scroll-parallax"
import { useNavbarHeight, useActiveSectionStore } from "../utils/store"
import Img, { FluidObject } from "gatsby-image"

interface Props {
  bannerOverlay?: string
  label: string
  children?: any
  noTopPadding?: boolean
  fluid?: FluidObject
  reducedHeight?: boolean
  noParallax?: boolean
}

const PageSection = ({
  bannerOverlay,
  children,
  fluid,
  label,
  noTopPadding,
  reducedHeight,
  noParallax,
}: Props) => {
  const renderBannerImage = (bannerOverlay?: string, fluid?: FluidObject) => {
    const layers: BannerLayer[] = []

    if (fluid) {
      layers.push({
        amount: noParallax ? 0 : 0.5,
        children: <Img fluid={fluid} />,
      })
    }

    if (bannerOverlay) {
      layers.push({
        children: (
          <div className="banner-overlay-container d-sm-flex flex-column justify-content-center h-100 w-100 align-items-center ">
            <h2>{bannerOverlay}</h2>
          </div>
        ),
        amount: 0,
      })
    }

    return <ParallaxBanner layers={layers} style={{ height: "40vw" }} />
  }

  const renderBannerOverlay = () => {
    if (fluid) return renderBannerImage(bannerOverlay, fluid)
    if (!bannerOverlay) return null
    return (
      <Col>
        <h2 className="text-center">{bannerOverlay}</h2>
      </Col>
    )
  }

  const navbarHeight = useNavbarHeight()
  const paddingTop = noTopPadding ? 0 : navbarHeight

  const sectionRef = useRef(null)

  const setActiveSection = useActiveSectionStore()[1]

  const scrollListener = () => {
    const { pageYOffset } = window
    const { offsetTop, offsetHeight } = sectionRef.current || {
      offsetTop: -1,
      offsetHeight: 0,
    }
    const offsetBottom = offsetTop + offsetHeight

    if (
      offsetTop >= 0 &&
      pageYOffset >= offsetTop &&
      pageYOffset <= offsetBottom
    ) {
      setActiveSection(label)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollListener)

    return () => {
      window.removeEventListener("scroll", scrollListener)
    }
  }, [scrollListener])

  const sectionStyles: {
    paddingTop: number
    minHeight?: string
  } = { paddingTop }

  if (!reducedHeight) {
    sectionStyles.minHeight = "100vh"
  }

  return (
    <section
      className="d-flex justify-content-start flex-column page-section"
      style={sectionStyles}
      ref={sectionRef}
    >
      <Container fluid>
        <Row>{renderBannerOverlay()}</Row>
      </Container>
      <div id={label}>{children}</div>
    </section>
  )
}

export default PageSection
